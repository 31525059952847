

@font-face {
    font-family: Raleway;
    src: url(../public/fonts/Raleway-Bold.ttf);
  }


  @font-face {
    font-family: RalewayMedium;
    src: url(../public/fonts/Raleway-Medium.ttf);
  }
  



@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.bg-metal{
    background-image: url(./components/Assets/homeBackground.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
}
.bg-metalgroup{
  background-image: url(./components/Assets/logoMetalAbout.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
}


.active {
  position: relative;
}

.active::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0; /* Set initial width to 0 */
  height: 2px;
  background-color: #444;
  border-radius: 1px;
  transition: width 0.3s ease; /* Add a transition for the width property */
}

.active:hover::after {
  width: 100%; /* Expand to 100% on hover for a visual indication */
}

.active::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0%;
  width: 26px; /* Set the width to 50% of the line */
  height: 2px;
  background-color: #444;
  border-radius: 1px;
  transition: width 0.7s ease; /* Add a transition for the width property */
}



